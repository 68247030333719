import React from "react";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
// import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import { classes } from "../Data/Layouts";
import SignIn from "../SignIn/SignIn";
import { getToken } from "firebase/messaging";
import { messaging } from "../firebase";
import axios from "../Schemas/API";
import EmailUser from "../User/EmailUser";
// import Contact from "../Contact/Contact";
// import BusinessCategory from "../BusinessCategory/BusinessCategory";
// import SidebarMenuItems from "../Layout/Sidebar/SidebarMenuItems";
// import SidebarMenu from "../Layout/Sidebar/SidebarMenu";

// setup fake backend

const Routers = () => {
  // const login = useState(JSON.parse(localStorage.getItem("login")))[0];
  // const [authenticated, setAuthenticated] = useState(false);
  // const defaultLayoutObj = classes.find(
  //   (item) => Object.values(item).pop(1) === "compact-wrapper"
  // );
  // const layout =
  //   localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();
  // useEffect(() => {
  //   let abortController = new AbortController();
  //   setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
  //   console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
  //   console.disableYellowBox = true;
  //   return () => {
  //     abortController.abort();
  //   };
  // }, []);
  // const [store, setStore] = useState("");
  // const reqastPermition = async () => {
  //   const permition = await Notification.requestPermission();
  //   try {
  //     if (permition === "granted") {
  //       const token = await getToken(messaging, {
  //         vapidKey:
  //           "BEOklhcJDoHPiTtMUtSwLbAjeydJLrpl-wwO8FriRaFRgDUREjLdbAggtVAgK_dQLy0rAvM_6Sa8rP2Vh1-GQ_Y",
  //       });
  //       console.log(token);
  //       localStorage.setItem("FCM", token);
  //     } else {
  //       console.log("Notification permission denied");
  //     }
  //   } catch (error) {
  //     console.error("Error requesting permission:", error);
  //   }
  // };
  // useEffect(() => {
  //   reqastPermition();
  // }, []);

  // // getToken API Function........

  // const getokens = async () => {
  //   const getid = localStorage.getItem("FCM");
  //   // console.log(getid);
  //   setStore(getid);
  //   try {
  //     const res = await axios.post(
  //       "/getToken",
  //       {
  //         device_id: store,
  //         device_type: "web",
  //       },
  //       {
  //         headers: {
  //           Accept: "application/json",
  //           "content-Type": "application/json",
  //         },
  //       }
  //     );
  //     localStorage.setItem("TOKEN", res?.data.data.token);
  //     // console.log(res);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getokens();
  // }, []);
  return (
    <BrowserRouter basename={"/"}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/*" element={<LayoutRoutes />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
