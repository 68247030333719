import BusinessAdd from "../BusinessAdd/BusinessAdd";
import BusinessCategory from "../BusinessCategory/BusinessCategory";
import Contact from "../Contact/Contact";
import Dashboard from "../DashBoard/Dashboard";
import Post from "../Post/Post";
import SignIn from "../SignIn/SignIn";
import EmailUser from "../User/EmailUser";
import SocialUser from "../User/SocialUser";
import Postview from "../Post/Postview";
import Comment from "../Post/Comment";
import BusinessLanguage from "../Language/BusinessLanguage";
import Support from "../Support/Support";
import PostList from "../Report/PostList";
import UserList from "../Report/UserList";
// import AddLanguage from "../Language/AddLanguage";
import UserDeatil from "../UserDeatil/UserDeatil";
import ContactList from "../Contact/ContactList";

export const routes = [
  {
    path: `/Contact`,
    Component: <Contact />,
  },
  {
    path: `/Support`,
    Component: <Support />,
  },
  {
    path: `/industry-category-list`,
    Component: <BusinessCategory />,
  },
  {
    path: "/SingIn",
    Component: <SignIn />,
  },
  {
    path: "/create-business-category",
    Component: <BusinessAdd />,
  },
  {
    path: `/business-email-user`,
    Component: <EmailUser />,
  },
  {
    path: `/business-social-user`,
    Component: <SocialUser />,
  },
  {
    path: "/Dashboard",
    Component: <Dashboard />,
  },
  {
    path: "/Post",
    Component: <Post />,
  },
  {
    Path: "/Postview",
    Component: <Postview />,
  },
  {
    path: "/Comment",
    Component: <Comment />,
  },
  {
    path: "/report-post-list",
    Component: <PostList />,
  },
  {
    path: "/report-user-list",
    Component: <UserList />,
  },

  {
    path: "/setting-add-label",
    Component: <BusinessLanguage />,
  },
  {
    path: `/business-user-deatil`,
    Component: <UserDeatil />,
  },
  {
    path: "/Conatct",
    Component: <ContactList />,
  },
];
