import React, { useEffect, useState } from "react";
import axios from "../Schemas/API";
import Prev from "../Images/previous.png";
import Next from "../Images/next.png";
import Eye from "../Images/eye.png";
import moment from "moment";
import { useNavigate } from "react-router";

function PostList() {
  const [getList, setGetList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const getPostList = async (page) => {
    const MyToken = localStorage.getItem("TOKEN");
    try {
      const res = await axios.post(
        "/report/post-list",
        {
          page: page.toString(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      setGetList(res.data.data);
      setTotalPages(res.data.totalPages);
      // console.log(res, "jataaaa");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPostList(currentpage);
  }, [currentpage]);
  const handlepage = (newpage) => {
    const nextPage = Math.max(newpage, 1);

    setCurrentpage(nextPage);
  };

  const handleDash = (item) => {
    navigate("/PostView", {
      state: {
        item,
      },
    });
  };

  const truncateMessage = (message, maxWords) => {
    const words = message.split("");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join("") + "...";
    } else {
      return message;
    }
  };
  const navigate = useNavigate();
  return (
    <div className="new-add-important-flex">
      {/* <Sidebar /> */}
      <div className="Main-Content-class">
        <div className="page-content">
          <div
            className="Category-product"
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="three-ones">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ color: "#0a58ca" }}
              >
                <path
                  fill="currentColor"
                  d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                />
              </svg>
              <a
                style={{
                  color: "#008cff",
                  fontWeight: "500",
                  fontSize: "16px",
                  textDecoration: "none",
                }}
                href=""
              >
                Post Listing
              </a>
            </div>
          </div>
          <hr className="hr-tag-class" />
          <div className="table-responsive-add">
            {/* <div className="serch-beetwin-two"></div> */}
            <div className="easy-add-scroll-new">
              <div className="card" style={{ marginTop: "2rem" }}>
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      id="example"
                      className="table table-striped table-bordered"
                    >
                      <thead>
                        <tr>
                          <th style={{ fontWeight: "bold" }}>Sr No.</th>
                          <th style={{ fontWeight: "bold" }}>Image</th>
                          <th style={{ fontWeight: "bold" }}>Sender Name </th>
                          <th style={{ fontWeight: "bold" }}>Reason</th>
                          <th style={{ fontWeight: "bold" }}>ReasonMsg</th>
                          <th style={{ fontWeight: "bold" }}>Title</th>
                          <th style={{ fontWeight: "bold" }}>CreatAt</th>
                          <th style={{ fontWeight: "bold" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getList && getList.length > 0 ? (
                          getList.map((item, index) => {
                            const serialNumber = index + 1;
                            return (
                              <>
                                <tr>
                                  <td>{serialNumber}</td>
                                  <td>
                                    <img
                                      src={item.PostGallery}
                                      alt="null_img"
                                      style={{
                                        width: "60px",
                                        height: "60px",
                                        borderRadius: "10px",
                                        border: "1px solid #e6e6e6",
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <td className="responsive-design">
                                      {item.Buyuser_Name}
                                    </td>
                                  </td>
                                  <td>{truncateMessage(item.Reason, 30)}</td>
                                  <td>{item.ReasonMsg}</td>

                                  <td>{truncateMessage(item.PostDesc, 30)}</td>
                                  <td>
                                    {moment
                                      .unix(item.CreatAt)
                                      .format("YYYY-MM-DD HH:mm:ss")}
                                  </td>
                                  <button
                                    className="delete-buttons-red"
                                    style={{
                                      border: "1px solid #15ca20",
                                      marginTop: "2rem",
                                    }}
                                    onClick={() => handleDash(item)}
                                  >
                                    <img
                                      src={Eye}
                                      alt="Delete"
                                      className="delete-btn-red"
                                    />
                                  </button>
                                </tr>
                              </>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="8">Data Not Found</td>
                          </tr>
                        )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th style={{ fontWeight: "bold" }}>Sr No.</th>
                          <th style={{ fontWeight: "bold" }}>Image</th>
                          <th style={{ fontWeight: "bold" }}>Sender Name </th>
                          <th style={{ fontWeight: "bold" }}>Reason</th>
                          <th style={{ fontWeight: "bold" }}>ReasonMsg</th>
                          <th style={{ fontWeight: "bold" }}>Title</th>
                          <th style={{ fontWeight: "bold" }}>CreatAt</th>
                          <th style={{ fontWeight: "bold" }}>Action</th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <div className="Page-button-click">
                <div onClick={() => handlepage(currentpage - 1)}>
                  <img src={Prev} alt="" className="both-Next-Prev" />
                </div>
                <span style={{ fontSize: "20px" }}>{currentpage}</span>
                <div
                  onClick={() =>
                    handlepage(
                      totalPages == currentpage ? currentpage : currentpage + 1
                    )
                  }
                >
                  <img
                    src={Next}
                    style={{ opacity: totalPages == currentpage ? "0.5" : "1" }}
                    alt=""
                    className="both-Next-Prev"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostList;
