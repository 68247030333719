import axios from "../../Schemas/API";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

const InvesterView = () => {
  const [list, setList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const MyToken = localStorage.getItem("TOKEN");

  const route = useLocation();
  const Invester = route?.state?.item;
  const getList = async () => {
    try {
      const res = await axios.post(
        "resources/get-insvester-detail",
        {
          id: Invester.ids,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      setList(res.data.data);
      console.log(res, "Listed Fine here");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getList();
  }, []);
  // const handlepage = (newpage) => {
  //   const nextPage = Math.max(newpage, 1);
  //   setCurrentpage(nextPage);
  // };
  const navigate = useNavigate();
  return (
    <div className="new-add-important-flex">
      <div className="Main-Content-class">
        <div
          className="page-content"
          style={{ marginTop: "2rem", alignItems: "flex-end" }}
        >
          <div
            className="Category-product"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="three-ones">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ color: "#0a58ca" }}
              >
                <path
                  fill="currentColor"
                  d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                />
              </svg>
              <a
                style={{
                  color: "#008cff",
                  fontWeight: "500",
                  fontSize: "16px",
                  textDecoration: "none",
                }}
                href=""
              >
                InvesterView
              </a>
              <span>Invester</span>
            </div>
            <button
              style={{
                width: "100px",
                height: "40px",
                background: "red",
                borderRadius: "4px",
                color: "#fff",
                border: "none",
              }}
              onClick={() => navigate("/Invester")}
            >
              Back
            </button>
          </div>
          <hr className="hr-tag-class" />
          <div className="table-responsive-add">
            <div className="Contact-span-flexed">
              <span>Image:-</span>
              <div>
                {/* <span>{list.name}</span> */}
                {list.image ? (
                  <img
                    src={list.image}
                    alt=""
                    style={{ width: "30px", height: "30px" }}
                  />
                ) : (
                  <img
                    src="https://turkapp.s3.us-east-1.amazonaws.com/1707574522374-Group_3_%281%29.png"
                    alt=""
                    style={{ width: "30px", height: "30px" }}
                  />
                )}
              </div>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Name:-</span>
              <div>
                <span>{list.name}</span>
              </div>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Email:-</span>
              <span>{list.email}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Number:-</span>
              <span>{list.contact_number}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>City:-</span>
              <span>{list.City}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Country:-</span>
              <span>{list.Country}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>State:-</span>
              <span>{list.State}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Location:-</span>
              <span>{list.location}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Website:-</span>
              <span>{list.website}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Stages :-</span>
              <span>{list.stages}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Operating Countries :-</span>
              <span>{list.operating_countries}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Contact Details :-</span>
              <span>{list.contact_details}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Check Size :-</span>
              <span>{list.check_size}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Twitter :-</span>
              <span>{list.Twitter}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Sector :-</span>
              <span>{list.Sector}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>PracticeAreas :-</span>
              <span>{list.PracticeAreas}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Portfolio :-</span>
              <span>{list.Portfolio}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Overview :-</span>
              <span>{list.Overview}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>LinkstoKeyPeopleProfiles :-</span>
              <span>{list.LinkstoKeyPeopleProfiles}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>LinkedIn :-</span>
              <span>{list.LinkedIn}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>KeyPeopleInfo :-</span>
              <span>{list.KeyPeopleInfo}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>KeyPeopleEmailIds :-</span>
              <span>{list.KeyPeopleEmailIds}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Investortype :-</span>
              <span>{list.Investortype}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Investmentthesis :-</span>
              <span>{list.Investmentthesis}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>GlobalHQ :-</span>
              <span>{list.GlobalHQ}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>FoundedYear :-</span>
              <span>{list.FoundedYear}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Firstchequeminimum :-</span>
              <span>{list.Firstchequeminimum}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Firstchequemaximum :-</span>
              <span>{list.Firstchequemaximum}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>FeedName :-</span>
              <span>{list.FeedName}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Facebook :-</span>
              <span>{list.Facebook}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>Description :-</span>
              <span>{list.Description}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>BusinessModels :-</span>
              <span>{list.BusinessModels}</span>
            </div>
            <hr />
            <div className="Contact-span-flexed">
              <span>BlogUrl :-</span>
              <span>{list.BlogUrl}</span>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvesterView;
