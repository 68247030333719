// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyB6eayv8cVAKkMxSSi3dpOAp80vwqqK7-I",
  authDomain: "trukafrica-8d294.firebaseapp.com",
  projectId: "trukafrica-8d294",
  storageBucket: "trukafrica-8d294.appspot.com",
  messagingSenderId: "771656327713",
  appId: "1:771656327713:web:dcfa9f7e54345d11e57960",
  measurementId: "G-LW9BYKY5Z8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
