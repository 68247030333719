import React, { useEffect, useState } from "react";
import axios from "../Schemas/API";
import Next from "../Images/next.png";
import Prev from "../Images/previous.png";
import "../Contact/Contact.css";
import Serch from "../Images/search.png";
import Eye from "../Images/eye.png";
import { useNavigate } from "react-router";
const Contact = () => {
  const [list, setList] = useState([]);
  // console.log(list.id);
  const [currentpage, setCurrentpage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const GetContact = async (page) => {
    const MyToken = localStorage.getItem("TOKEN");
    console.log(MyToken, "tokens");
    try {
      const res = await axios.post(
        "/contactus/get-web-list",
        {
          page: page.toString(),
          keyword: searchQuery,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      console.log(res, "dasa");
      setList(res.data.data);
      setTotalPages(res.data.totalPages);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetContact(currentpage);
  }, [currentpage, searchQuery]);

  const handlepage = (newpage) => {
    const nextPage = Math.max(newpage, 1);
    setCurrentpage(nextPage);
  };
  // truncateMessageLogic Here..............................

  const truncateMessage = (message, maxWords) => {
    const words = message.split("");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join("") + "...";
    } else {
      return message;
    }
  };

  const handlemessage = (item) => {
    navigate("/ContactList", {
      state: {
        item,
      },
    });
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="new-add-important-flex">
        <div className="Main-Content-class">
          <div className="page-content">
            <div
              className="Category-product"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "2rem",
              }}
            >
              <div className="three-ones">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{ color: "#0a58ca" }}
                >
                  <path
                    fill="currentColor"
                    d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                  />
                </svg>
                <a
                  style={{
                    color: "#008cff",
                    fontWeight: "500",
                    fontSize: "16px",
                    textDecoration: "none",
                  }}
                  href=""
                >
                  Contact Listing
                </a>
              </div>
            </div>
            <hr className="hr-tag-class" />
            <div className="table-responsive-add">
              <div className="serch-beetwin-two">
                <div
                  style={{
                    gap: "0.4rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "280px",
                    border: "1px solid grey",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <img
                    src={Serch}
                    alt=""
                    style={{ width: "24px", height: "24px" }}
                  />
                  <input
                    className="form-control form-control-sm"
                    type="text"
                    style={{
                      borderRadius: "4px",
                      outline: "none",
                      border: "1px solid #ced4da",
                      width: "280px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                    id="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>

              <div className="easy-add-scroll-new">
                <div className="card" style={{ marginTop: "2rem" }}>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        id="example"
                        className="table table-striped table-bordered"
                      >
                        <thead>
                          <tr>
                            <th style={{ fontWeight: "bold" }}>Sr No.</th>
                            <th style={{ fontWeight: "bold" }}>Name</th>
                            <th style={{ fontWeight: "bold" }}>Email</th>
                            <th style={{ fontWeight: "bold" }}>PhoneNumber</th>
                            <th style={{ fontWeight: "bold" }}>BusinessName</th>
                            <th style={{ fontWeight: "bold" }}>Position</th>
                            <th style={{ fontWeight: "bold" }}>country</th>
                            <th style={{ fontWeight: "bold" }}>Message</th>
                            <th style={{ fontWeight: "bold" }}>CreatedAt</th>
                            <th style={{ fontWeight: "bold" }}>Action</th>

                            {/* <th style={{ fontWeight: "bold" }}>Status</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {list && list.length > 0 ? (
                            list.map((item, index) => {
                              const serialNumber = index + 1;
                              return (
                                <>
                                  <tr>
                                    <td>{serialNumber}</td>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "0.5rem",
                                        }}
                                      >
                                        <div className="responsive-design">
                                          {item.firstname}
                                        </div>
                                        {item.lastname}
                                      </div>
                                    </td>
                                    <td>{item.email}</td>
                                    <td> {item.phonenumber}</td>
                                    <td>{item.businessname}</td>
                                    <td>{item.position}</td>
                                    <td>{item.country}</td>
                                    <td>{truncateMessage(item.message, 30)}</td>
                                    <td className="responsive-time-here">
                                      {item.createdAt}
                                    </td>
                                    <td
                                      style={{ width: "120px", height: "80px" }}
                                    >
                                      <button
                                        className="delete-buttons-red"
                                        style={{
                                          border: "1px solid #15ca20",
                                          marginTop: "0.5rem",
                                        }}
                                        onClick={() => handlemessage(item)}
                                      >
                                        <img
                                          src={Eye}
                                          alt="Delete"
                                          className="delete-btn-red"
                                        />
                                      </button>
                                    </td>
                                    {/* <td className="td-list-api">
                                      <div className="pending-here-status">
                                        {item.status}
                                      </div>
                                    </td> */}
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            // <span>Data Not Found</span>
                            <tr>
                              <td colSpan="8">Data Not Found</td>
                            </tr>
                          )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th style={{ fontWeight: "bold" }}>Sr No.</th>
                            <th style={{ fontWeight: "bold" }}>Name</th>
                            <th style={{ fontWeight: "bold" }}>Email</th>
                            <th style={{ fontWeight: "bold" }}>PhoneNumber</th>
                            <th style={{ fontWeight: "bold" }}>BusinessName</th>
                            <th style={{ fontWeight: "bold" }}>Position</th>
                            <th style={{ fontWeight: "bold" }}>Country</th>
                            <th style={{ fontWeight: "bold" }}>Message</th>
                            <th style={{ fontWeight: "bold" }}>CreatedAt</th>
                            <th style={{ fontWeight: "bold" }}>Action</th>

                            {/* <th style={{ fontWeight: "bold" }}>Status</th> */}
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="Page-button-click">
                  <div onClick={() => handlepage(currentpage - 1)}>
                    <img src={Prev} alt="" className="both-Next-Prev" />
                  </div>
                  <span style={{ fontSize: "20px" }}>{currentpage}</span>
                  <div
                    onClick={() =>
                      handlepage(
                        totalPages == currentpage
                          ? currentpage
                          : currentpage + 1
                      )
                    }
                  >
                    <img
                      src={Next}
                      style={{
                        opacity: totalPages == currentpage ? "0.5" : "1",
                      }}
                      alt=""
                      className="both-Next-Prev"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
