import React, { useEffect, useState } from "react";
import axios from "../Schemas/API";
import "../BusinessAdd/BusinessAdd.css";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
// import { useNavigate } from "react-router";
const BusinessAdd = () => {
  const [language, setLanguage] = useState([]);
  const MyToken = localStorage.getItem("TOKEN");
  const [categories, setCategories] = useState([]);
  // const [categories, setCategories] = useState([
  //   { language_id: "", cat_name: "" },
  //   { language_id: "", cat_name: "" },
  // ]);

  const navigate = useNavigate();
  const handleInputChange = (index, value, id) => {
    const updatedCategories = [...categories];
    updatedCategories[index] = {
      language_id: id,
      cat_name: value,
    };
    setCategories(updatedCategories);
  };

  // getLanguage API  FUNCTION ....................................
  const getLanguage = async () => {
    const MyToken = localStorage.getItem("TOKEN");

    try {
      const res = await axios.get("/setting/get-language", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${MyToken}`,
        },
      });
      setLanguage(res.data.data);
      console.log(res, "get ki api");
      // console.log(res.data.data[0].id, "get Lanuage ka data....");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getLanguage();
  }, []);

  const addCategory = async (categories) => {
    try {
      const res = await axios.post(
        "/business/add-category",
        {
          Category: categories,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      toast.success("Category added successfully!");
      navigate("/industry-category-list");
      console.log(res, "AddCategory Ka Data..");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="new-add-important-flex">
        <div className="Main-Content-class">
          <div className="page-content-add">
            <div className="New-add-Categories">
              <span>Create Industry Category</span>
              <div className="three-ones">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{ color: "#0a58ca" }}
                >
                  <path
                    fill="currentColor"
                    d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                  />
                </svg>
                <a
                  style={{
                    color: "#008cff",
                    fontWeight: "500",
                    fontSize: "16px",
                    textDecoration: "none",
                  }}
                  href=""
                >
                  Industry
                </a>
                <span>Category</span>
                <span>Create Category</span>
              </div>
            </div>
            <div className="card" style={{ marginTop: "1rem", width: "100%" }}>
              <div
                className="card-body"
                style={{
                  borderTop: "3px solid #008cff",
                  borderRadius: "5px",
                }}
              >
                <form action="" className="row-g-3">
                  <div className="col-12">
                    <div className="grid-class-in-form">
                      {language.map((item, index) => {
                        return (
                          <div className="form-flex-adde">
                            <label
                              className="form-label"
                              htmlFor={`input-${item.id}`}
                            >
                              Category Name in {item.name}
                            </label>
                            <input
                              required
                              type="text"
                              id={`input-${item.id}`}
                              className="form-control"
                              autocomplete="text"
                              name={`input-${item.cat_name}`}
                              autofocus
                              onChange={(e) =>
                                handleInputChange(
                                  index,
                                  e.target.value,
                                  item.id
                                )
                              }
                            />
                          </div>
                        );
                      })}
                    </div>

                    <button
                      className="add-Create-button"
                      type="submit"
                      onClick={() => addCategory(categories)}
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessAdd;
