import { useContext } from "react";
import { Link } from "react-router-dom";
import CustomizerContext from "../../_helper/Customizer";
import Turklogo2 from "../../Images/TurkLogo2.jpg";
const SidebarIcon = () => {
  const { layoutURL } = useContext(CustomizerContext);
  return (
    <div className="logo-icon-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`}>
        <img
          className="img-fluid"
          src={Turklogo2}
          style={{ width: "auto", height: "40px" }}
        />
      </Link>
    </div>
  );
};

export default SidebarIcon;
