import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FileText, LogIn, Mail, User } from "react-feather";
import man from "../../../assets/images/dashboard/profile.png";
import "react-toastify/dist/ReactToastify.css";
import { LI, UL, Image, P } from "../../../AbstractElements";
// import CustomizerContext from "../../../_helper/Customizer";
import { Account, Admin, Inbox, LogOut, Taskboard } from "../../../Constant";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const UserHeader = () => {  
  // const history = useNavigate();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("Emay Walter");

  const uzair = JSON.parse(localStorage.getItem("token"));
  // useEffect(() => {
  //   setProfile(localStorage.getItem("profileURL") || man);
  //   setName(localStorage.getItem("Name") ? localStorage.getItem("Name") : name);
  // }, []);

  const navigate = useNavigate();

  const Logouthandler = () => {
    toast.success("Logout successfully");
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0">
      <div className="media profile-media">
        <img
          src={uzair.data.data.detail.image}
          style={{
            width: "42px",
            height: "42px",
            borderRadius: "50%",
            padding: "0",
            cursor: "pointer",
          }}
        />
        <div className="media-body">
          <span>
            {uzair.data.data.detail.firstname + uzair.data.data.detail.lastname}
          </span>
          <P attrPara={{ className: "mb-0 font-roboto" }}>
            {Admin} <i className="middle fa fa-angle-down"></i>
          </P>
        </div>
      </div>
      <UL
        attrUL={{ className: "simple-list profile-dropdown onhover-show-div" }}
      >
        <LI
          attrLI={
            {
              // onClick: () =>
              //   UserMenuRedirect(
              //     `${process.env.PUBLIC_URL}/app/users/userProfile/${layoutURL}`
              //   ),
            }
          }
        >
          <User />
          <span>{Account} </span>
        </LI>
        <LI
          attrLI={
            {
              // onClick: () =>
              //   UserMenuRedirect(
              //     `${process.env.PUBLIC_URL}/app/email-app/${layoutURL}`
              //   ),
            }
          }
        >
          <Mail />
          <span>{Inbox}</span>
        </LI>
        <LI
          attrLI={
            {
              // onClick: () =>
              //   UserMenuRedirect(
              //     `${process.env.PUBLIC_URL}/app/todo-app/todo/${layoutURL}`
              //   ),
            }
          }
        >
          <FileText />
          <span>{Taskboard}</span>
        </LI>
        <LI onClick={() => Logouthandler()}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: "0.5rem",
              marginLeft: "1rem",
            }}
          >
            <span onClick={Logouthandler}>{LogOut}</span>
          </div>
        </LI>
      </UL>
    </li>
  );
};

export default UserHeader;
