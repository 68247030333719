export const MENUITEMS = [
  {
    Items: [
      {
        title: "Dashboard",
        type: "sub",
        badge: "badge badge-light-primary",
        badgetxt: "",
        active: false,
        path: `/Dashboard`,
      },
      {
        title: "industry",
        type: "sub",
        badgetxt: "",
        active: false,
        children: [
          {
            path: "/industry-category-list",
            title: "Category",
            type: "link",
          },
        ],
      },

      {
        title: "Contact Form",
        type: "sub",
        badge: "badge badge-light-primary",
        badgetxt: "",
        active: false,
        path: `/Contact`,
      },
      {
        title: "Support",
        type: "sub",
        badge: "badge badge-light-primary",
        badgetxt: "",
        active: false,
        path: `/Support`,
      },
      {
        title: "business",
        type: "sub",
        badgetxt: "",
        active: false,
        children: [
          {
            path: "/business-email-user",
            title: "Email",
            type: "link",
          },
          {
            path: "/business-social-user",
            title: "Social",
            type: "link",
          },
          {
            path: "/business-user-deatil",
            title: "Userdeatil",
            type: "link",
          },
        ],
      },
      {
        title: "Post",
        type: "sub",
        badge: "",
        badgetxt: "",
        active: false,
        path: `/Post`,
      },
      {
        title: "Setting",
        type: "sub",
        badgetxt: "",
        active: false,
        children: [
          {
            path: "/setting-add-label",
            title: "Label",
            type: "link",
          },
        ],
      },
      {
        title: "Report",
        type: "sub",
        badgetxt: "",
        active: false,
        children: [
          {
            path: "/report-post-list",
            title: "Post",
            type: "link",
          },
          {
            path: "/report-user-list",
            title: "User",
            type: "link",
          },
        ],
      },
      {
        title: "Invester",
        type: "sub",
        badge: "badge badge-light-primary",
        badgetxt: "",
        active: false,
        path: `/Invester`,
      },
    ],
  },
];
