import * as yup from "yup";

export const LoginSchemas = yup.object({
  email: yup
    .string()
    .email("Email Must be Valid Email")
    .required("Email is Required"),
  password: yup.string().required("Password is Required"),
});

export const LableSchemas = yup.object({
    Labeltitle: yup.string().required("App Label title is Required"),
  EnglishLang: yup.string().required("Label Name in English is Required"),
  TurkishLang: yup.string().required("Label Name in Turkish is Required"),
  FrenchLang: yup.string().required("Label Name in French is Required"),
  PortugueseLang: yup.string().required("Label Name in Portuguese is Required"),
  SpanishLang: yup.string().required("Label Name in Spanish is Required"),
  SwahiliLang: yup.string().required("Label Name in Swahili is Required"),
});
